<template>
  <v-dialog v-model="cafDialog" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar flat height="46" class="transparent">
          <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Agregar foliación</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn class="ml-3" @click="$emit('update:cafDialog', false), clearInput()" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-4">
        <v-row align="center" no-gutters>
          <v-col cols="3" class="pa-0">
            <img :src="require(`@/assets/backgrounds/modal-folios-upload.svg`)" :height="160" />
          </v-col>
          <v-col cols="9" class="pr-0 pl-15 pt-0">
            <div class="mb-3" v-if="$store?.state?.auth?.account?.country === 'CL'">
              <span class="d-block mt-3 body-1 grey-700--text font-weight-bold mb-2">Archivo de folios (CAF)</span>
              <div
                class="file-drop-area"
                @dragover.prevent="onDragOver"
                @dragleave="onDragLeave"
                @drop.prevent="onDrop"
                :class="{ 'drag-over': isDragging }"
              >
                <v-file-input
                  v-model="$v.newCaf.caf.$model"
                  :error="$v.newCaf.caf.$error"
                  @change="handlerFile"
                  v-if="!newCaf.caf"
                  :key="`${!newCaf.caf?.length}-${resetFileInput}`"
                  class="ml-n1 custom-file"
                  ref="newCaf"
                  type="file"
                  accept=".xml"
                  background-color="transparent"
                  :height="50"
                  hide-details
                  solo
                  flat
                  chips
                  :style="`z-index: 10; border-color: ${$v.newCaf.caf.$error || cafFileType ? 'red' : ''}`"
                />

                <div :class="{'custom-border' : !!newCaf.caf}">
                  <div class="d-flex align-center justify-center custom-text-file" style="position: relative; z-index: 0;">
                    <div class="col-9 d-flex align-center" v-if="!newCaf.caf" style="margin-top: -68px;">
                    <div class="cols-1 d-flex">
                      <v-icon size="30" color="grey-700">mdi-cloud-upload-outline</v-icon>
                    </div>
                    <span class="mx-auto transparent">
                      <span class="d-block body-1 grey-700--text font-weight-medium">Haz click para seleccionar un archivo</span>
                      <span class="d-block body-2 grey-500--text">o arrastra el archivo aquí (xml)</span>
                    </span>
                  </div>
                    <div class="d-flex align-center col-12 mt-1" v-else>
                      <v-chip small label color="blue-100" style="max-width:60%">
                        <span class="d-inline-block text-truncate" style="max-width: 100%;">{{ cafNameFile }}</span>
                      </v-chip>
                      <v-spacer />
                      <v-btn @click="newCaf.caf=null,cafNameFile=null,isValidateDate=false" text color="grey-200" :ripple="false"><v-icon size="18">mdi-delete</v-icon></v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <v-col cols="12" class="px-0 pt-0">
              <span class="grey-500--text body-1">Tipo de documento</span>
              <v-autocomplete v-model="$v.newCaf.document_type.$model" :error="$v.newCaf.document_type.$error" label="Selecciona" :items="documentTypesList.filter(({country}) => country == $store?.state?.auth?.account?.country)" item-value="id" item-text="name" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details>
                <template v-slot:item="data">
                  <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" class="px-0 pt-0" v-if="$store?.state?.auth?.account?.country !== 'CL'">
              <span class="grey-500--text body-1">Serie</span>
              <v-text-field v-model="$v.newCaf.series.$model" :error="$v.newCaf.series.$error" outlined required single-line dense maxlength="100" hide-details />
            </v-col>
            <span class="mt-2 d-block font-weight-regular grey-500--text body-1"><v-icon class="mt-n1" size="16" left color="grey-700">mdi-pound-box-outline</v-icon>{{$t('generals.Folios')}}:</span>
            <v-row no-gutters>
              <v-col cols="5" class="pl-0 pr-1">
                <v-text-field v-model="$v.newCaf.start.$model" :error="$v.newCaf.start.$error" prefix="Desde" outlined required single-line dense maxlength="64" hide-details />
              </v-col>
              <v-col class="pl-2 pr-0">
                <v-text-field v-model="$v.newCaf.end.$model" :error="$v.newCaf.end.$error" prefix="Hasta" outlined required single-line dense maxlength="64" hide-details />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-col class="px-0" v-if="newCaf.caf">
          <span class="d-block mt-3 body-1 grey-700--text font-weight-bold">Información del CAF</span>
          <v-row no-gutters>
            <v-col cols="7">
              <div class="d-flex align-center mt-2">
                <v-icon color="grey-700" size="16" left>mdi-file-document-outline</v-icon>
                <span class="d-block">
                  <span class="font-weight-bold mr-2 grey-700--text body-1">Tipo de documento:</span>
                  <span v-if="folios.type" class="body-2">{{ folios.type | nameDocumentsType }}</span>
                  <span class="body-2 text--disabled" v-else>Sin especificar</span>
                </span>
              </div>
              <div class="d-flex align-center mt-2">
                <v-icon color="grey-700" size="16" left>mdi-domain</v-icon>
                <span class="d-block">
                  <span class="font-weight-bold mr-2 grey-700--text body-1">Razón social:</span>
                  <span v-if="folios.business" class="body-2">{{ folios.business }}</span>
                  <span class="body-2 text--disabled" v-else>Sin especificar</span>
                </span>
              </div>
              <div class="d-flex align-center mt-2">
                <v-icon color="grey-700" size="16" left>mdi-pound-box-outline</v-icon>
                <span class="d-block">
                  <span class="font-weight-bold mr-2 grey-700--text body-1 ">Rango:</span>
                  <span v-if="folios.startRange && folios.endRange" class="body-2">{{ folios.startRange }} - {{ folios.endRange }}</span>
                  <span class="body-2 text--disabled" v-else>Sin especificar</span>
                </span>
              </div>
            </v-col>
            <v-col>
              <div class="d-flex align-center mt-2">
                <v-icon color="grey-700" size="16" left>mdi-calendar-blank</v-icon>
                <span class="d-block">
                  <span class="font-weight-bold mr-2 grey-700--text body-1">Fecha de aprobación:</span>
                  <span v-if="folios.date" class="body-2">{{ folios.date | date }}</span>
                  <span class="body-2 text--disabled" v-else>Sin especificar</span>
                </span>
              </div>
              <div class="d-flex align-center mt-2">
                <v-icon color="grey-700" size="16" left>mdi-file-document</v-icon>
                <span class="d-block">
                  <span class="font-weight-bold mr-2 grey-700--text body-1">RUT:</span>
                  <span v-if="folios.rut" class="body-2">{{ folios.rut }}</span>
                  <span class="body-2 text--disabled" v-else>Sin especificar</span>
                </span>
              </div>
              <div class="d-flex align-center mt-2">
                <v-icon color="grey-700" size="16" left>mdi-file-multiple-outline</v-icon>
                <span class="d-block">
                  <span class="font-weight-bold mr-2 grey-700--text body-1">Cantidad de folio:</span>
                  <span v-if="folios.cant" class="body-2">{{ folios.cant | number }}</span>
                  <span class="body-2 text--disabled" v-else>Sin especificar</span>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-5 py-2">
        <v-spacer/>
        <v-btn outlined @click="$emit('update:cafDialog', false), clearInput()">Cancelar</v-btn>
        <v-btn color="blue-500" :loading="loadingCreateCaf" :disabled="isValidateDate" @click="createNumbering">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from 'moment'
import { required, requiredIf } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import documentTypesList from '@/collections/documentTypes'

export default {
  mixins: [
    GenericViewMixin
  ],
  props: {
    cafDialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isDragging: false,
    documentTypesList,
    resetFileInput: 0,
    newCaf: {
      document_type: '',
      series: '',
      start: '',
      end: '',
      caf: null
    },
    folios: {
      cant: 0,
      type: '',
      date: null,
      business: '',
      startRange: 0,
      endRange: 0,
      rut: ''
    },
    loadingCreateCaf: false,
    numberingList: [],
    cafNameFile: null,
    cafTypeFile: null,
    isNameEditable: false,
    isValidateDate: false,
    cafFileType: false
  }),
  watch: {
    'folios.type': {
      handler (val) {
        if (['CL34', 'CL61', 'CL56', 'CL46'].includes(val) && this.$store?.state?.auth?.account?.country === 'CL') {
          const expirationDate = moment(this.folios.date, "YYYY-MM-DD").add(6, "months").format("YYYY-MM-DD")
          if (moment().isAfter(expirationDate)) {
            this.isValidateDate = true
            this.$dialog.message.error('El CAF está vencido. Por favor, solicita un nuevo.')
          }
        }
      },
        deep: true
    },
    cafDialog: {
      handler (val) {
        this.cafDialog = val
        if (!val) this.clearInput()
      },
      inmediate: true,
      deep: true
    }
  },
  methods: {
    onDragOver () {
      this.isDragging = true
    },
    onDragLeave () {
      this.isDragging = false
    },
    onDrop (event) {
      this.cafFileType = false
      this.isDragging = false
      const files = event.dataTransfer.files

      if (files.length > 0) {
        const file = files[0]

        if (file.type === "text/xml" || file.name.endsWith(".xml")) {
          this.$v.newCaf.caf.$model = file
          this.handlerFile(file)
        } else {
          this.cafFileType = true
          this.$dialog.message.error('El archivo seleccionado no es un XML. Por favor, sube un archivo con extensión .xml')
          this.resetFileInput += 1
        }
      }
    },
    clearInput () {
      this.$v.newCaf.$reset()
      this.newCaf = {
        document_type: '',
        series: '',
        start: '',
        end: '',
        caf: null
      }
      this.cafTypeFile = null
      this.cafNameFile = null
    },
    createNumbering () {
      let payload = {}
      this.$v.newCaf.$touch()
      if (this.$v.newCaf.$invalid) {
        return false
      }

      this.loadingCreateCaf = true
      if (this.$store?.state?.auth?.account?.country === 'CL') {
        const cafFile = new Blob([this.newCaf.caf], { type: "application/xml" })
        const formDataDocTxt = new FormData()
        formDataDocTxt.append('caf', this.newCaf.caf, this.newCaf.caf.name)
        const combinedFormData = new FormData()
        combinedFormData.append('allocate_body', JSON.stringify({
          document_type: this.newCaf.document_type,
          series: this.newCaf.series,
          start: this.newCaf.start,
          end: this.newCaf.end,
          branch_id: this.$route?.params?.id ?? null,
          test: this.$route.path.startsWith('/test')
        }))
        combinedFormData.append('caf', cafFile, cafFile.name)
        payload = combinedFormData
      } else {
        payload = {
          ...this.newCaf,
          branch_id: this.$route.params.id,
          test: this.$route.path.startsWith('/test')
        }
      }

      this.$store.dispatch('branches/CREATE', {
        resource: `numbering${this.$store?.state?.auth?.account?.country === 'CL' ? '/with-caf' : ''}`,
        payload: payload,
        ...(this.$store?.state?.auth?.account?.country === 'CL' && {headers: { 'Content-Type': 'multipart/form-data' }})
      })
      .then(() => {
        this.$dialog.message.info(this.$store?.state?.auth?.account?.country === 'CL' ? 'Folio guardado correctamente' : 'La numeración fue creada correctamente')
        this.$emit('getList')
        this.$emit('update:cafDialog', false)
        this.clearInput()
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
      .finally(() => {
        this.loadingCreateCaf = false
        this.dialogNumbering = false
      })
    },
    handlerFile (e) {
      this.isValidateDate = false
      this.cafFileType = false
      this.cafNameFile = e?.name ?? null
      this.folios = {
        cant: 0,
        type: '',
        date: null,
        business: '',
        startRange: 0,
        endRange: 0,
        rut: ''
      }
      const file = e
      let rngD = ''
      let rngH = ''

      if (file && file.type === "text/xml") {
        const reader = new FileReader()

        reader.onload = (e) => {
          const xmlContent = e.target.result
          const parser = new DOMParser()
          const xmlDoc = parser.parseFromString(xmlContent, "application/xml")
          const parseError = xmlDoc.getElementsByTagName("parsererror")[0]
          if (parseError) {
            throw new Error("El archivo no es un XML válido.")
          }

        const tdElement = xmlDoc.getElementsByTagName("TD")[0]
        if (tdElement) {
          this.folios.type = `CL${tdElement.textContent}`
          this.cafTypeFile = this.folios.type
        }
        const rsElement = xmlDoc.getElementsByTagName("RS")[0]
        if (rsElement) {
          this.folios.business = rsElement.textContent
        }
        const reElement = xmlDoc.getElementsByTagName("RE")[0]
        if (reElement) {
          this.folios.rut = reElement.textContent
        }
        const faElement = xmlDoc.getElementsByTagName("FA")[0]
        if (faElement) {
          this.folios.date = faElement.textContent
        }

        const rngElement = xmlDoc.getElementsByTagName("RNG")[0]
        if (rngElement) {
          const dElement = rngElement.getElementsByTagName("D")[0]
          const hElement = rngElement.getElementsByTagName("H")[0]

          rngD = dElement ? dElement.textContent : "Sin especificar"
          rngH = hElement ? hElement.textContent : "Sin especificar"
          this.folios.cant = parseInt(rngH) - parseInt(rngD) + 1
          this.folios.startRange = parseInt(rngD)
          this.folios.endRange = parseInt(rngH)
        }
        }
        reader.readAsText(file)
      } else {
        this.$dialog.message.error('El archivo seleccionado no es un XML. Por favor, sube un archivo con extensión .xml')
        this.resetFileInput += 1
        this.cafFileType = true
        this.cafNameFile = null
        this.newCaf.caf = null
      }
    }
  },
  validations: {
    newCaf: {
      document_type: {
        required,
        matchesVariable (value) {
          return this.$store?.state?.auth?.account?.country === 'CL' ? value === this.cafTypeFile : true
        }
      },
      series: {
        required: requiredIf(function () {
          return this.$store?.state?.auth?.account?.country !== 'CL'
        })
      },
      start: {
        required,
        inRange (value) {
          if (!value) return true
          return this.$store?.state?.auth?.account?.country === 'CL' ? value >= this.folios.startRange && value <= this.folios.endRange : true
        }
      },
      end: {
        required,
        inRange (value) {
          if (!value) return true
          return this.$store?.state?.auth?.account?.country === 'CL' ? value >= this.folios.startRange && value <= this.folios.endRange : true
        }
      },
      caf: {
        required: requiredIf(function () {
          return this.$store?.state?.auth?.account?.country === 'CL'
        })
      }
    }
  }
}
</script>